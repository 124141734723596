import React from "react";
import Layout from "components/Layout";
import routes from "routes";
import PrimaryButton from "components/PrimaryButton";
import { useAuth } from "hooks/auth";
import TradingViewWidget from "components/TradingViewWidget";
export default function Home() {
  const { user } = useAuth();
  return (
    <Layout
      description="Send money to and from Cameroon, Canada & the USA."
      title={`Send money "to and from" Cameroon, Canada & the USA.`}
      btnPath={user.isAuthenticated ? routes.SendMoney.path : routes.Login.path}
      btnText="Send Now"
      titleCenter
    >
      <section className="easy-steps">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-10 m-auto">
              <div className="sec-heading">
                <h2 className="sec-title">Fast, Easy & Reliable.</h2>
                <p className="sec-subtitle">
                  With our services, you can send money online 24/7. All from
                  the power of your fingertips! You can create a transfer with
                  these simple steps:
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-10 col-md-12 m-auto">
              <ul className="steps">
                <li>
                  <span>1</span> Enter amount
                </li>
                <li>
                  <span>2</span> Choose Recipient
                </li>
                <li>
                  <span>3</span> Place transfer order
                </li>
                <li>
                  <span>4</span> ⁠ ⁠Make Payment
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="payment-service bg-offwhite">
        <div className="container">
          <div className="flex items-center flex-col md:flex-row justify-center gap-10 lg:gap-36">
            <div className="">
              <div className="content-box">
                <h2 className="text-3xl">Why CelaTrans Connect</h2>
                <ul className="list space-y-1">
                  <li>
                    <i className="fas fa-check-circle"></i> Fast Processing
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i> Secured & guaranteed
                    delivery
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i> Best exchange rates
                    with zero fees.
                  </li>
                </ul>
                <div className="w-max">
                  <PrimaryButton href={routes.Signup.path}>
                    Try it now
                  </PrimaryButton>
                </div>
              </div>
            </div>
            <div className="flex-1">
              <TradingViewWidget />
            </div>
          </div>
        </div>
      </section>

      <section className="services">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-10 m-auto">
              <div className="sec-heading">
                <h2 className="sec-title">
                  Reliable transfers every time you send money back home
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="iconBox boxed text-center">
                <img src="/images/icons/3.png" />
                <h5>Safe and secure</h5>
                <p>
                  Your safety is our top priority. Multi-level security keeps
                  your money and data protected.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="iconBox boxed text-center">
                <img src="/images/icons/2.png" />
                <h5>24/7 Customer Support</h5>
                <p>Fast and friendly support for you and your loved ones.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="iconBox boxed text-center">
                <img src="/images/icons/1.png" />

                <h5>Real Time Money Tracking</h5>
                <p>Track your money as it gets delivered to your recipient</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
