import React, { useEffect } from "react";
import Layout from "components/Layout";
import SendMoneyComponent from "components/SendMoney";
import useDispatch from "hooks/dispatch";
import apiClient from "api/index";
import Loader from "components/Loader";

export default function SendMoney() {
  const { state, dispatch } = useDispatch({
    // amount: ,
    currency: "CAD",
    rate: { USD: 612.99, CAD: 457.38 },
    fee: 0,
  });

  const fetchConfig = async () => {
    dispatch({ loading: true });
    try {
      let response = await apiClient({ method: "get", url: "/config" });
      let config = response.data?.config ?? {};
      dispatch({
        loading: false,
        contacts: response.data?.contact,
        ...config,
      });
    } catch (error) {
      dispatch({ loading: false });
    }
  };

  useEffect(() => {
    fetchConfig();
  }, []);

  return (
    <Layout title="Send Money">
      {state.loading ? (
        <div className="h-[700px] justify-center items-center flex">
          <Loader size={80} />
        </div>
      ) : (
        <SendMoneyComponent {...state} state={state} dispatch={dispatch} />
      )}
    </Layout>
  );
}
