import classNames from "classnames";
import React from "react";

export default function Button(props) {
  return <ButtonComponent {...props} />;
}

const ButtonComponent = ({
  children,
  className,
  loading,
  loadingText = "Loading...",
  onClick= () => {},
  ...rest
}) => {
  const cn = classNames({
    ["btn btn-default hover:text-white hover:bg-primary/80"]: true,
    [className]: true,
  });
  return (
    <button
      disabled={loading}
      className="btn btn-primary w-100 rounded-full"
      onClick={onClick}
      {...rest}
    >
      {loading ? <> {loadingText} </> : <>{children}</>}
    </button>
  );
};
