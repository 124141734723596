import { useTranslation } from "react-i18next";

export function useTranslate() {
  const { t: translate, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
  return {
    translate,
    languages: i18n.languages,
    changeLanguage,
  };
}
