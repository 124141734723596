import React from "react";
import { Link } from "react-router-dom";
import routes from "routes";
import NavLink from "./NavLink";
import { useAuth } from "hooks/auth";
import { FaBars, FaTimes } from "react-icons/fa";
import { useBoolean } from "hooks/useBoolean";
import { useTranslate } from "hooks/translate";
import Logo from "components/logo";

export default function Header() {
  const { user, logoutUser } = useAuth();
  const { open, toogle, onClose } = useBoolean();
  const { translate } = useTranslate();
  const { open: menu, toogle: toogleMenu } = useBoolean();
  return (
    <header className={`header04 bg-white !relative`}>
      <div className="container d-flex align-items-center">
        <div>
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <nav className="primary-menu m-auto">
          <ul>
            <NavLink
              path={
                user.isAuthenticated ? routes.SendMoney.path : routes.Login.path
              }
              name={translate("send_money")}
            />
            <NavLink path={routes.Fees.path} name={translate("fees")} />
            <NavLink path={routes.About.path} name={translate("about")} />
            <NavLink path={routes.Contact.path} name={translate("contact")} />
          </ul>
        </nav>
        <div className="flex">
          {user.isAuthenticated && (
            <div className="flex items-center gap-2">
              <div className="relative">
                <a
                  onClick={toogle}
                  className="w-10 h-10 pt-1 mr-3 flex justify-center items-center font-bold bg-primary text-white rounded-full cursor-pointer"
                >
                  {user.name?.charAt(0)?.toUpperCase()}
                </a>

                {open && (
                  <div className="absolute top-12 right-1 flex flex-col space-y-2 bg-white shadow-lg p-3 pr-5 rounded h-max w-max">
                    <div
                      onClick={onClose}
                      className="fixed w-screen h-screen text-base inset-0 -z-10"
                    />
                    <Link to={routes.Transactions.path}>
                      <a className="cursor-pointer">
                        {translate("transactions")}
                      </a>
                    </Link>
                    <Link to={routes.Profile.path}>
                      <a className="cursor-pointer">
                        {translate("profile_setting")}
                      </a>
                    </Link>
                    <a
                      onClick={logoutUser}
                      className="!text-red-500 cursor-pointer"
                    >
                      {translate("logout")}
                    </a>
                  </div>
                )}
              </div>
            </div>
          )}
          {!user.isAuthenticated && (
            <Link to={routes.Login.path}>
              <a className="login btn-default text-white hover:!text-black py-3 !m-0 !mr-4 min-w-[100px]">
                {translate("login")}
              </a>
            </Link>
          )}

          <button onClick={toogleMenu} className="!text-black lg:hidden">
            <FaBars />
          </button>
        </div>
      </div>

      {menu && (
        <div className="w-screen h-screen fixed inset-0 bg-[#256be6] p-4">
          <div className="relative">
            <div className="flex justify-end w-full absolute">
              <button onClick={toogleMenu} className="text-white text-2xl">
                <FaTimes />
              </button>
            </div>

            <ul className="text-white space-y-3 pt-10">
              <NavLink
                path={
                  user.isAuthenticated
                    ? routes.SendMoney.path
                    : routes.Login.path
                }
                name={translate("send_money")}
              />
              <NavLink path={routes.Fees.path} name={translate("fees")} />
              <NavLink path={routes.About.path} name={translate("about")} />
              <NavLink path={routes.Contact.path} name={translate("contact")} />
              {user.isAuthenticated && (
                <>
                  <hr className="bg-white/50" />
                  <NavLink
                    path={routes.Contact.path}
                    name={translate("profile_setting")}
                  />
                  <button onClick={logoutUser} className="text-red-400">
                    Logout
                  </button>
                </>
              )}
            </ul>

            <div className="pt-10">
              {!user.isAuthenticated && (
                <Link to={routes.Login.path}>
                  <a className=" bg-white text-black px-4 rounded hover:!text-black py-2.5">
                    <span className="bh"></span>{" "}
                    <span>{translate("login")}</span>
                  </a>
                </Link>
              )}
            </div>
          </div>
        </div>
      )}
    </header>
  );
}
