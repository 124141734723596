import React from 'react'

export default function Logo() {
  return (
    <div>
        {/* <div className='text-2xl text-white'>{process.env.REACT_APP_WEB_NAME}</div> */}
        <img className='w-[120px] lg:w-[180px]' src="/assets/logo.png" />
    </div>
  )
}
