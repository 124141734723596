import { useState } from "react";

export function useBoolean(defaultState = false) {
  const [open, setOpen] = useState(defaultState);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);
  const toogle = () => setOpen(!open);

  return {
    open,
    onClose,
    onOpen,
    toogle,
  };
}
