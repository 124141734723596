import React from "react";
import Card from "../Card";
import SecondaryButton from "components/SecondaryButton";
import classNames from "classnames";
import { NumericFormat } from "react-number-format";
import apiClient from "api";
import PrimaryButton from "components/PrimaryButton";
import {
  getPromo,
  getPromoAmount,
  getPromoText,
  getTransactionFee,
} from "helpers/transaction";
import { FaPlus, FaTimes } from "react-icons/fa";
import InputField from "components/InputField";

export default function Review({
  reciever,
  rate = {},
  fee,
  feeType,
  contacts = [],
  currency,
  next,
  prev,
  amount,
  receiveAmount,
  dispatch,
  recieveTime,
  creatingTransaction,
  state,
}) {
  const contact = contacts.find((contact) => contact._id === reciever) ?? {};
  // const recieveAmount = parseInt((amount - fee) * rate[currency]);
  const submitTransaction = async () => {
    const data = {
      amount,
      contact: reciever,
      currency,
      coupon: state.couponData?._id,
      receiveAmount,
    };

    try {
      dispatch({ creatingTransaction: true, error: null });

      let response = await apiClient({
        method: "post",
        url: "/transaction",
        data,
      });
      if (!response.success) {
        throw new Error(response.message ?? "An error occured");
      }
      dispatch({ creatingTransaction: false, transactionData: response.data });
      next();
    } catch (error) {
      dispatch({ creatingTransaction: false, error: error.message });
    }
  };

  const fetchCouponData = async () => {
    try {
      dispatch({ loadingCoupon: true, couponError: null });
      let response = await apiClient({
        method: "get",
        url: "/coupon/" + state.coupon,
        params: {amount}
      });
      dispatch({
        loadingCoupon: false,
        couponData: response.data,
        coupon: "",
        showCoupon: false,
      });
    } catch (error) {
      dispatch({ couponError: error.message || "Coupon is invalid", loadingCoupon: false });
    }
  };

  const toggleCoupon = () => {
    dispatch({ showCoupon: !state.showCoupon, coupon: "", couponError: null });
  };

  const { feeAmount, paidAmount } = getTransactionFee({
    amount,
    currency,
    fee,
    feeType,
    rateCAD: rate.CAD,
    rateUSD: rate.USD,
    receiveAmount,
  });
  return (
    <>
      <Card title="Reciever">
        <div className="space-y-8">
          <div className="space-y-5">
            <Info
              label="Amount"
              value={getPromoAmount({
                amount,
                currency,
                coupon: state.couponData,
              })}
            />
            <Info label="Currency" value={currency} />
            <Info
              label="Reciever"
              value={
                <span>
                  {contact.fullName} (
                  <span className="text-primary">{contact.wallet}</span>)
                </span>
              }
            />
            <Info
              isFee
              label="Fees"
              value={
                <NumericFormat
                  value={feeAmount}
                  thousandSeparator=" "
                  displayType="text"
                  suffix={" " + currency}
                />
              }
            />
            <Info
              label="Reciever recieves"
              value={getPromoAmount(
                {
                  paidAmount,
                  amount,
                  currency,
                  coupon: state.couponData,
                },
                false
              )}
            />
            <Info label="Estimated time" value={recieveTime} />
            {state.couponData && (
              <Info
                label={
                  <div className="flex gap-2">
                    Coupon:
                    <div className="flex items-center gap-2">
                      <span className="text-green-500">
                        {state.couponData?.code}
                      </span>
                      <button
                        onClick={() => {
                          dispatch({ couponData: null });
                        }}
                        className="text-red-500"
                      >
                        <FaTimes />
                      </button>
                    </div>
                  </div>
                }
                value={
                  <span className="text-green-500">
                    {getPromoText(state.couponData, currency)}{" "}
                    {state.couponData?.affect === "R" ? "ADDITION" : "OFF"}
                  </span>
                }
              />
            )}

            <div>
              {!state.showCoupon && !state.couponData && (
                <TextButton onClick={toggleCoupon} icon={<FaPlus />}>
                  Add Coupon
                </TextButton>
              )}

              {state.showCoupon && !state.couponData && (
                <div className="flex gap-4 items-center">
                  <div className="flex-1">
                    <InputField
                      onChange={(e) => {
                        dispatch({ coupon: e.target.value, couponError: null });
                      }}
                      value={state.coupon}
                      disabled={state.loadingCoupon}
                      placeholder="Coupon code"
                      error={state.couponError}
                    />
                  </div>
                  <div className="flex gap-4">
                    <PrimaryButton
                      disabled={!state.coupon || state.loadingCoupon}
                      className="!m-0 min-w-[100px]"
                      onClick={fetchCouponData}
                    >
                      Add
                    </PrimaryButton>
                    <SecondaryButton
                      onClick={toggleCoupon}
                      className="min-w-[100px]"
                      disabled={state.loadingCoupon}
                    >
                      Cancel
                    </SecondaryButton>
                  </div>
                </div>
              )}
            </div>
          </div>
          {(!state.showCoupon || state.couponData) && (
            <div className="flex items-center flex-col-reverse md:flex-row gap-4">
              <SecondaryButton onClick={prev}>Modify</SecondaryButton>
              <PrimaryButton
                loading={creatingTransaction}
                onClick={submitTransaction}
              >
                Place Order
              </PrimaryButton>
            </div>
          )}
        </div>
      </Card>
    </>
  );
}

const Info = ({ label, value, isAmount, isFee }) => {
  const cn = classNames({
    ["text-primary"]: isAmount,
    ["text-red-500"]: isFee,
  });
  return (
    <div className="flex justify-between items-center">
      <span>{label}</span>
      <span className={cn}>{value}</span>
    </div>
  );
};

const TextButton = ({ children, icon, ...rest }) => {
  const Icon = () => icon;
  return (
    <button {...rest} className="flex items-center gap-2 text-primary">
      {" "}
      <Icon /> {children}
    </button>
  );
};
