import React from "react";
import Layout from "components/Layout";
import CardWrapper from "components/CardWrapper";
import InputField from "components/InputField";
import Button from "components/Button";
import PasswordField from "components/InputField/PasswordField";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import routes from "routes";
import { useAuth } from "hooks/auth";
import { useTranslate } from "hooks/translate";

export default function Login() {
  const { userLoading, loginError, loginUser } = useAuth();
  const { translate } = useTranslate();
  const initialValues = {
    emailAddress: "",
    password: "",
  };

  const validationSchema = yup.object({
    emailAddress: yup
      .string()
      .email(translate("valid_email"))
      .required(translate("field_required")),
    password: yup.string().required(translate("field_required")),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: loginUser,
  });

  return (
    <Layout title="Login">
      <CardWrapper>
        <div className="w-full max-w-xl bg-white md:shadow-lg border xl:p-8 p-6 rounded-lg mx-auto">
          <div className="space-y-6">
            <InputField
              value={formik.values.emailAddress}
              name="emailAddress"
              error={formik.errors.emailAddress || loginError ?  translate(loginError) : null}
              onChange={formik.handleChange}
              label={translate("email_address")}
            />
            <PasswordField
              label={translate("password")}
              value={formik.values.password}
              name="password"
              error={formik.errors.password || loginError ?  translate(loginError) : null}
              onChange={formik.handleChange}
              rightElement={<>
              <Link to={routes.ForgetPassword.path}>
              <a className="!text-red-500 hover:!text-red-700 hover:!underline cursor-pointer">
                {translate("forget_password")} 
              </a>
              </Link>
              </>}
            />
            <Button
              onClick={formik.handleSubmit}
              loading={userLoading}
              className="w-full"
            >
              {translate("login")}
            </Button>
          </div>

          <div className="border-t mt-10 pt-4 text-center">
            <p>
              {translate("no_account_text")} &nbsp;
              <a className="text-primary font-semibold">
                <Link to={routes.Signup.path}>
                  {translate("create_an_account")}
                </Link>
              </a>{" "}
            </p>
          </div>
        </div>
      </CardWrapper>
    </Layout>
  );
}
