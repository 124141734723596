import React from "react";
import Layout from "components/Layout";
import CardWrapper from "components/CardWrapper";
import Button from "components/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslate } from "hooks/translate";
import apiClient from "api/index";
import useDispatch from "hooks/dispatch";
import Alert from "components/Alert";
import errors from "helpers/errors";
import PasswordField from "components/InputField/PasswordField";
import { useParams } from "react-router-dom";

export default function ChangePassword() {
  const { translate } = useTranslate();
  const params = useParams()
  let token = params.token;
  const {state, dispatch} = useDispatch();
  const initialValues = {
    password: "",
    cpassword: "",
  };

  const validationSchema = yup.object({
    password: yup
      .string()
      .oneOf([yup.ref("cpassword")], translate("password_not_match"))
      .required(translate("field_required")),
    cpassword: yup
      .string()
      .oneOf([yup.ref("password")], translate("password_not_match"))
      .required(translate("field_required")),
  });




  const resetPassword = async (data) => {
    dispatch({ loading: true });
    try {
      await apiClient({
        method: "post",
        url: "/change-password",
        data: {
          password: data.password,
        },
        _token: token
      });
      dispatch({ loading: false, success: true});
    } catch (error) {
      dispatch({error: true, loading: false})
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: resetPassword,
  });


  return (
    <Layout title="Change Password">
      <CardWrapper>
        <div className="w-full space-y-6 max-w-lg bg-white md:shadow-lg border  p-3 xl:p-8 rounded-lg mx-auto">
          <div>
            <h6 className="font-bold text-black text-2xl uppercase">
              {translate("change_password")}
            </h6>
          </div>

          {state.success && <Alert status="success" text={translate("password_reset")} />}
          {state.error && <Alert status="error" text={translate(errors.ERROR_OCCURED)} />}

          <div className="space-y-6">
          <PasswordField
              label={translate("new_password")}
              value={formik.values.password}
              name="password"
              disabled={state.loading}
              error={formik.errors.password}
              onChange={formik.handleChange}
            />
            <PasswordField
              label={translate("confirm_password")}
              value={formik.values.cpassword}
              name="cpassword"
              disabled={state.loading}
              error={formik.errors.cpassword}
              onChange={formik.handleChange}
            />
            <Button
              onClick={formik.handleSubmit}
              loading={state.loading}
              className="w-full"
            >
              {translate("change_password")}
            </Button>
          </div>
        </div>
      </CardWrapper>
    </Layout>
  );
}
