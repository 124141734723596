import apiClient from "api/index";
import Card from "components/Card";
import CardWrapper from "components/CardWrapper";
import Layout from "components/Layout";
import Loader from "components/Loader";
import PrimaryButton from "components/PrimaryButton";
import useDispatch from "hooks/dispatch";
import React, { useEffect } from "react";
import { NumericFormat } from "react-number-format";
import { useParams } from "react-router-dom";
import routes from "routes/index";

export default function PaymentInstructions() {
  const { state, dispatch } = useDispatch({ transaction: {} });
  const params = useParams();

  const getTransactions = async () => {
    dispatch({ loading: true });
    try {
      const response = await apiClient({
        method: "get",
        url: "/transaction/" + params.id,
      });
      if (!response.data) {
        throw new Error("Invalid transaction Id");
      }
      dispatch({
        transaction: response.data,
        interacEmail: response.interacEmail,
        cashAppEmail: response.cashAppEmail,
        zelleEmail: response.zelleEmail,
        loading: false,
      });
    } catch (error) {
      dispatch({
        loading: false,
        error: error.message ?? "Something went wrong!",
      });
    }
  };
  useEffect(() => {
    getTransactions();
  }, []);

  return (
    <Layout title="Payment Information">
      {state.loading ? (
        <div className="h-[500px] pt-20 justify-center items-center flex">
          <Loader size={80} />
        </div>
      ) : (
        <CardWrapper>
          <Card title="Order Placed">
            <p className="text-center">
              Your transfer of
              <span className="text-primary">
                {" "}
                <NumericFormat
                  value={Number(state.transaction.amount)}
                  thousandSeparator=" "
                  displayType="text"
                  suffix={" " + state.transaction.currency}
                />{" "}
              </span>
              to{" "}
              <span className="text-primary">
                {state.transaction.contact?.fullName}
              </span>{" "}
              has been initiated. The funds will be deposited into the MTN
              Mobile Money Wallet soon.{" "}
              {getPaymentText({
                currency: state.transaction.currency,
                cashAppEmail: state.cashAppEmail,
                interacEmail: state.interacEmail,
                zelleEmail: state.zelleEmail,
              })}
              <br />
              <br />
              Use this reference in your payment{" "}
              <span className="text-primary tracking-wide">
                {state.transaction.refNumber}
              </span>
              .
            </p>

            <div className="mt-4">
              <PrimaryButton
                href={routes.ConsultTranaction.route + state.transaction._id}
              >
                Consult Transaction
              </PrimaryButton>
            </div>
          </Card>
        </CardWrapper>
      )}
    </Layout>
  );
}

const getPaymentText = ({
  currency,
  interacEmail,
  cashAppEmail,
  zelleEmail,
}) => {
  if (currency === "CAD") {
    return (
      <>
        Please, proceed to complete interac payment using{" "}
        <span className="text-primary">{interacEmail}</span>
      </>
    );
  }

  return (
    <>
      Please proceed to complete Zelle or CashApp payment using{" "}
      <span className="text-primary">{cashAppEmail}</span>
    </>
  );
};
