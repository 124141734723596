import React from "react";

export default function Select({
  label = "",
  placeholder = "Select",
  name,
  value,
  error,
  onChange,
  options = [],
}) {
  return (
    <div className="form-group">
      <label>{label}</label>
        <select className={`form-control input-field ${error ? "border-red-500" : ""}`} name={name} value={value}  onChange={onChange}>
          <option value=''>{placeholder}</option>
          {options?.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
        </select>
        {error && <p className="text-sm text-red-500">{error}</p>}
    </div>
  );
}
