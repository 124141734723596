import About from "pages/About";
import Contact from "pages/Contact";
import Fees from "pages/Fees";
import Home from "pages/Home";
import Login from "pages/auth/Login";
import SendMoney from "pages/SendMoney";
import Signup from "pages/auth/Signup";
import EmailNotVerified from "pages/auth/EmailNotVerified";
import EmailVerified from "pages/auth/EmailVerified";
import AccountCreated from "pages/auth/AccountCreated";
import AccountInActive from "pages/auth/AccountInActive";
import AccountExist from "pages/auth/AccountExist";
import ForgetPassword from "pages/auth/ForgetPassword";
import ChangePassword from "pages/auth/ChangePassword";
import VerifyAccount from "pages/auth/VerifyAccount";
import Transactions from "pages/Transactions";
import ConsultTranaction from "pages/ConsultTranaction";
import TrackTransaction from "pages/TrackTransaction";
import Profile from "pages/Profile";
import PaymentInstructions from "pages/payments-instructions";

const routes = {
    Home: {
        path: '/',
        component: Home
    },
    Contact: {
        path: '/contact',
        component: Contact
    },
    About: {
        path: '/about',
        component: About
    },

    Fees: {
        path: '/fees',
        component: Fees
    },
    Profile: {
        path: '/profile',
        component: Profile
    },
    SendMoney: {
        path: '/send-money',
        component: SendMoney
    },
    Transactions: {
        path: '/transactions',
        component: Transactions
    },
    TrackTransaction: {
        path: '/transactions/track/:id',
        route: '/transactions/track/',
        component: TrackTransaction
    },
    PaymentInstructions: {
        path: '/payment-info/:id',
        route: '/payment-info/',
        component: PaymentInstructions
    },
    ConsultTranaction: {
        path: '/transactions/:id',
        route: '/transactions/',
        component: ConsultTranaction
    },
    Login: {
        path: '/auth/login',
        component: Login
    },
    Signup: {
        path: '/auth/signup',
        component: Signup
    },
    EmailNotVerified: {
        path: '/auth/email-not-verified',
        component: EmailNotVerified
    },
    EmailVerified: {
        path: '/auth/email-verified',
        component: EmailVerified
    },
    AccountCreated: {
        path: '/auth/account-created',
        component: AccountCreated
    },
    AccountInActive: {
        path: '/auth/account-inactive',
        component: AccountInActive
    },
    AccountExist: {
        path: '/auth/account-exist',
        component: AccountExist
    },
    ForgetPassword: {
        path: '/auth/forget-password',
        component: ForgetPassword
    },
    ChangePassword: {
        path: '/auth/reset-password/:token',
        component: ChangePassword
    },
    VerifyAccount: {
        path: '/auth/verify-email/:token',
        component: VerifyAccount
    },

}

export default routes;