import Layout from 'components/Layout'
import ProfileSettings from 'components/Profile'
import React from 'react'

export default function Profile() {
  return (
    <Layout headerBg>
        <div className='py-32 container'>
            <ProfileSettings />
        </div>
    </Layout>
  )
}
