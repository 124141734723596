import InputField from "components/InputField";
import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslate } from "hooks/translate";
import Select from "components/Select";
import PrimaryButton from "components/PrimaryButton";

export default function UserInfo({
  userInfo = {},
  handleSubmit = () => {},
  saving,
  saved
}) {
  const { translate } = useTranslate();
  const initialValues = {
    ...userInfo,
    emailAddress: userInfo.email,
    phoneNumber: userInfo.phone,
  };

  const validationSchema = yup.object({
    emailAddress: yup
      .string()
      .email(translate("valid_email"))
      .required(translate("field_required")),
    fullName: yup.string().required(translate("field_required")),
    phoneNumber: yup.string().required(translate("field_required")),
    country: yup.string().required(translate("field_required")),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  return (
    <div className="space-y-5">
      <div className="gap-4 gap-x-8 mt-10 md:grid grid-cols-2">
        <InputField
          value={formik.values.fullName}
          name="fullName"
          disabled={saving}
          error={formik.errors.fullName}
          onChange={formik.handleChange}
          label={translate("full_name")}
        />

        <InputField
          value={formik.values.emailAddress}
          name="emailAddress"
          disabled={saving}
          error={formik.errors.emailAddress}
          onChange={formik.handleChange}
          label={translate("email_address")}
        />

        <InputField
          value={formik.values.phoneNumber}
          name="phoneNumber"
          disabled={saving}
          error={formik.errors.phoneNumber}
          onChange={formik.handleChange}
          label={translate("phone_number")}
        />
        <Select
          value={formik.values.country}
          name="country"
          disabled={saving}
          error={formik.errors.country}
          onChange={formik.handleChange}
          label={translate("country")}
          options={[
            { label: "Canada", value: "CAD" },
            { label: "USA", value: "USD" },
          ]}
        />
      </div>

      <div>
        <PrimaryButton onClick={formik.submitForm} className="!w-max" loading={saving} loadingText="Saving...">{saved ? "Saved" : "Save"}</PrimaryButton>
      </div>
    </div>
  );
}
