import React from "react";

export default function Card({ title, children }) {
  return (
    <div className="p-4 lg:p-8 border send-container currency-form max-w-xl">
      {/* <h6> {title}</h6> */}
      <div>{children}</div>
    </div>
  );
}
