import classNames from "classnames";
import { useQuery } from "hooks";
import moment from "moment";
import React, { useEffect, useState } from "react";
import UserInfo from "./UserInfo";
import PrimaryButton from "components/PrimaryButton";
import apiClient from "api/index";
import Loader from "components/Loader";
import { useNavigate } from "react-router-dom";
import Security from "./Security";
import useDispatch from "hooks/dispatch";

const tabs = {
  UserInfo: {
    component: UserInfo,
    name: "Profile Information",
  },
  Security: {
    component: Security,
    name: "Change Password",
  },
};

export default function ProfileSettings() {
  const navigate = useNavigate();
  const query = useQuery();
  const { dispatch, state } = useDispatch();

  const [currentTab, setCurrentTab] = useState(
    query.get("tab") || Object.keys(tabs)[0]
  );

  let current = tabs[currentTab];

  function switchTabs(tab) {
    navigate(window.location.pathname + `?tab=${tab}`);
    setCurrentTab(tab);
  }

  const linkclassName = (isActive) =>
    classNames({
      "font-medium  cursor-pointer py-2 px-4": true,
      " rounded-full bg-secondary text-white": isActive,
    });

  async function handleSubmit(data) {
    dispatch({ saving: true });
    try {
      await apiClient({ method: "put", url: "/user", data });
      dispatch({ saving: false, saved: true });
    } catch (error) {
      dispatch({ saving: false, error: true });
    }
  }

  async function savePassword(data) {
    dispatch({ changingPassword: true });
    try {
      await apiClient({ method: "put", url: "/user/password", data });
      dispatch({ changingPassword: false, savedP: true });
    } catch (error) {
      dispatch({ changingPassword: false, error: true });
    }
  }

  const fetchProfile = async () => {
    dispatch({ loading: true });
    try {
      let res = await apiClient({ method: "get", url: "/user" });
      dispatch({ userInfo: res.data, loading: false });
    } catch (error) {
      dispatch({ loading: false });
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);


  return (
    <div>
      <div className="w-screen max-w-5xl mx-auto mt-10 space-y-8  px-4">
        <div className="flex justify-between  items-center">
          <h4 className="text-3xl text-black font-semibold">Settings</h4>
        </div>
        <div className="lg:flex lg:space-x-20">
          <div className="space-y-10">
            <ul className="space-y-2">
              {Object.keys(tabs).map((tab) => (
                <li
                  key={tab}
                  onClick={() => {
                    switchTabs(tab);
                  }}
                  className={linkclassName(currentTab === tab)}
                >
                  {tabs[tab].name}
                </li>
              ))}
            </ul>
          </div>
          <div className="flex-1">
            <h4 className="text-xl text-black font-semibold">{current.name}</h4>
            {state.loading ? (
              <div className="w-full h-20 flex justify-center items-center">
                <Loader size={40} />
              </div>
            ) : (
              <div layout="vertical">
                {Object.keys(tabs).map((tab) => {
                  const Component = tabs[tab].component;
                  let className = classNames({
                    hidden: tab !== currentTab,
                  });
                  return (
                    <div className={className} key={tab}>
                      <Component
                        {...state}
                        handleSubmit={handleSubmit}
                        savePassword={savePassword}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
