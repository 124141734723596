import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

export default function PrimaryButton({ href, ...props }) {
  if (href) {
    return (
      <Link to={href}>
        <ButtonComponent {...props} />
      </Link>
    );
  }
  return <ButtonComponent {...props} />;
}

const ButtonComponent = ({
  children,
  onClick,
  loading,
  loadingText = "Loading...",
  className,
  ...rest
}) => {
  const cn = classNames({
    "btn btn-primary w-100 rounded-full": true,
    [className]: true,
  });
  return (
    <button disabled={loading} onClick={onClick} {...rest} className={cn}>
      {loading ? (
        <div className="flex items-center justify-center gap-3">
          <div>
          <ClipLoader color={"#ffffff"} loading={true} size={16} />
          </div>
          {loadingText}{" "}
        </div>
      ) : (
        <>{children}</>
      )}
    </button>
  );
};
