import React from "react";
import { Helmet } from "react-helmet";

export default function SEO({
  title = "Home",
  description = "Send money to and from Cameroon, Canada & the USA.",
}) {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <script src="/gtag.js" defer></script>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-GN1N28BL6Q"
      ></script>

      <meta name="description" content={description} />
      <title>CelaTrans Connect - {title}</title>
      <link rel="canonical" href="https://celatransinternational.com" />
    </Helmet>
  );
}
