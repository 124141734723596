import React from "react";
import Layout from "components/Layout";
import CardWrapper from "components/CardWrapper";
import Button from "components/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslate } from "hooks/translate";
import apiClient from "api/index";
import useDispatch from "hooks/dispatch";
import Alert from "components/Alert";
import errors from "helpers/errors";
import InputField from "components/InputField";

export default function ForgetPassword() {
  const { translate } = useTranslate();
  const { state, dispatch } = useDispatch();
  const initialValues = {
    emailAddress: "",
  };

  const validationSchema = yup.object({
    emailAddress: yup
      .string()
      .email(translate("valid_email"))
      .required(translate("field_required")),
  });

  const resetPassword = async (data) => {
    dispatch({ loading: true });
    try {
      await apiClient({
        method: "post",
        url: "/send-reset-email",
        data: data,
      });
      dispatch({ loading: false, success: true });
    } catch (error) {
      dispatch({ error: true, loading: false });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: resetPassword,
  });

  return (
    <Layout title="Forget Password">
      <CardWrapper>
        <div className="w-full space-y-6 max-w-xl bg-white md:shadow-lg border p-6 xl:p-8 rounded-lg mx-auto">
          <div>
            <p>{}</p>
          </div>

          {state.success && (
            <Alert status="success" text={translate("reset_email_sent")} />
          )}
          {state.error && (
            <Alert status="error" text={translate(errors.ERROR_OCCURED)} />
          )}

          <div className="space-y-6">
            <InputField
              value={formik.values.emailAddress}
              name="emailAddress"
              error={formik.errors.emailAddress}
              onChange={formik.handleChange}
              placeholder={translate("email_address")}
              label={translate("reset_password_text")}
            />
            <Button
              onClick={formik.handleSubmit}
              loading={state.loading}
              className="w-full"
            >
              {translate("reset_password")}
            </Button>
          </div>
        </div>
      </CardWrapper>
    </Layout>
  );
}
