import React from "react";
import { Link } from "react-router-dom";

export default function Banner({ title, titleCenter, btnText, btnPath }) {
  return (
    <section className="banner v7 ">
      <div className="container">
        <div className={`flex flex-col gap-6 relative ${titleCenter ? "items-center" : "md:items-start"} items-center `}>
          <h1 className={`${titleCenter ? "text-center" : "md:!text-left"} text-center text-white text-2xl md:text-4xl max-w-lg `}>
            {title}
          </h1>
          {btnText && <Link to={btnPath}>
            <a className="btn btn-outline btn-round icon-left">
              <span className="bh"></span>{" "}
              <span>
                <i className="fas fa-play"></i> {btnText}
              </span>
            </a>
          </Link>}
        </div>
      </div>
    </section>
  );
}
