import React, { useEffect } from "react";
import Layout from "components/Layout";
import { FaWhatsapp } from "react-icons/fa";
import useDispatch from "hooks/dispatch";
import apiClient from "api/index";
import Loader from "components/Loader";

export default function Contact() {
  const { dispatch, state } = useDispatch();

  const fetchInfo = async () => {
    try {
      dispatch({ loading: true });
      const data = await apiClient({ method: "get", url: "/info" });
      dispatch({ data: data.data, loading: false });
    } catch (error) {
      dispatch({ loading: false });
    }
  };

  useEffect(() => {
    fetchInfo();
  }, []);
  return (
    <Layout title="Contact" hideBanner>
      {state.loading ? (
        <div className="h-[700px] justify-center items-center flex">
          <Loader size={80} />
        </div>
      ) : (
        <>
          <section className="banner">
            <div className="container">
              <div className="row">
                <div className="col-md-8 m-auto">
                  <div className="ban-content text-center space-y-8">
                    <h1>Contact Information</h1>
                    {/* <p>Chat with on us today on WhatsApp</p> */}

                    <div>
                      <a
                        href={
                          "https://wa.me/" +
                          state.data?.whatsappNumber?.replace("+", "")
                        }
                        className="video-btn flex justify-center items-center"
                        data-fancybox
                        target="_blank"
                      >
                        <FaWhatsapp fontSize="28px" className="z-20" />
                      </a>
                      <span className="simple-txt">
                        Chat with us on WhatsApp
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="contact">
            <div className="container">
              <div className="  flex justify-center gap-20 flex-col md:flex-row">
                <Address
                  contact={state.data?.email}
                  icon="fas fa-envelope"
                  title="Email Address"
                  url={"mailto:" + state.data?.email}
                />
                <Address
                  contact={state.data?.supportPhone}
                  icon="fas fa-phone-alt"
                  title="Phone Number"
                  url={"tel:" + state.data?.supportPhone}
                />
              </div>
            </div>

            <div className="container mt-20">
              <div className="space-y-4">
              <h6 className="text-black text-center text-2xl">Address</h6>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2503.099910198764!2d-113.95180318724567!3d51.14350867161491!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x537163c5ad620ae1%3A0x517829a044061ac3!2s8664%20Cityscape%20Dr%20NE%2C%20Calgary%2C%20AB%20T3N%201B4%2C%20Canada!5e0!3m2!1sen!2srw!4v1712238704562!5m2!1sen!2srw"
                width="100%"
                height="450"
                style={{border: "1px solid #fcfafa"}}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
              </div>
            </div>
          </section>
        </>
      )}
    </Layout>
  );
}

const Address = ({ icon, title, contact, url }) => {
  return (
    <div className="">
      <address className="flex items-center flex-col  text-xl justify-center gap-4 w-full max-w-sm">
        <div className="flex flex-col text-black items-center gap-2 text-center">
          <i style={{ fontSize: 35 }} className={icon}></i>
          <span className="text-md">{title}</span>
        </div>

        <a className="!text-black text-center" href={url}>
          {contact}
        </a>
      </address>
    </div>
  );
};
