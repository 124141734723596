import { Link } from "react-router-dom";

export default function NavLink({ name, path, subMenu = [], children }) {
  return (
    <li className={subMenu.length > 0 ? "has-menu-child" : ""}>
      <Link to={path}>{name}</Link>
      {subMenu.length > 0 && <ul className="sub-menu">{children}</ul>}
    </li>
  );
}
