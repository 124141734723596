import React from "react";
import Layout from "components/Layout";

export default function About() {
  return (
    <Layout title="Fast and easy way to transfer money to Cameroon">
      <section className="about-overview">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-10 m-auto">
              <div className="sec-heading">
                <h2 className="sec-title">What Service We Provide</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="iconBox boxed-shadow h-full text-center">
                <img
                  src="/images/icons/5.png"
                  className="roysha-icon roysha-fast-transfer"
                />

                <h5>
                  <a href="#">Send Money</a>
                </h5>
                <p>
                  Send money to your families in Cameroon with less fees.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="iconBox boxed-shadow text-center">
                <img
                  src="/images/icons/3.png"
                  className="roysha-icon roysha-fast-transfer"
                />

                <h5>
                  <a href="#">Safe and secure</a>
                </h5>
                <p>
                  Your safety is our top priority. Multi-level security keeps
                  your money and data protected.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="iconBox boxed-shadow h-full text-center">
                <img
                  src="/images/icons/1.png"
                  className="roysha-icon roysha-fast-transfer"
                />

                <h5>
                  <a href="#">Real Time Money Tracking</a>
                </h5>
                <p>Track your money as it gets delivered to your recipient.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
