import React from "react";
import Layout from "components/Layout";
import CardWrapper from "components/CardWrapper";
import InputField from "components/InputField";
import Button from "components/Button";
import PasswordField from "components/InputField/PasswordField";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import routes from "routes";
import Select from "components/Select";
import useDispatch from "hooks/dispatch";
import apiClient from "api";
import { useTranslate } from "hooks/translate";
import errors from "helpers/errors";

export default function Signup() {
  const { state, dispatch } = useDispatch();
  const navigate = useNavigate();
  const { translate } = useTranslate();
  const initialValues = {
    emailAddress: "",
    password: "",
    fullName: "",
    phoneNumber: "",
    country: "",
    cpassword: "",
  };

  const validationSchema = yup.object({
    emailAddress: yup
      .string()
      .email(translate("valid_email"))
      .required(translate("field_required")),
    fullName: yup.string().required(translate("field_required")),
    phoneNumber: yup.string().required(translate("field_required")),
    country: yup.string().required(translate("field_required")),
    password: yup
      .string()
      .oneOf([yup.ref("cpassword")], translate("password_not_match"))
      .required(translate("field_required")),
    cpassword: yup
      .string()
      .oneOf([yup.ref("password")], translate("password_not_match"))
      .required(translate("field_required")),
  });

  const signupUser = async (user) => {
    dispatch({ loading: true });
    try {
      await apiClient({
        method: "post",
        url: "/register",
        data: user,
      });
      dispatch({ loading: false });
      navigate(routes.AccountCreated.path);
    } catch (error) {
      dispatch({ loading: false });
      console.log(error.message);
      if (error.message === errors.ACCOUNT_EXIST) {
        navigate(routes.AccountExist.path)
      } else {
        dispatch({error: errors.ERROR_OCCURED})
      }

    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: signupUser,
  });

  return (
    <Layout title="Register">
      <CardWrapper>
        <div className="w-full max-w-xl bg-white md:shadow-lg border p-6 xl:p-8 rounded-lg mx-auto">

          {state.error && <div className="mt-10 bg-red-50 text-red-500 px-4 py-3 rounded">
            {translate(state.error)}
          </div>}

          <div className="gap-4 gap-x-8">
            <InputField
              value={formik.values.fullName}
              name="fullName"
              disabled={state.loading}
              error={formik.errors.fullName}
              onChange={formik.handleChange}
              label={translate("full_name")}
            />

            <InputField
              value={formik.values.emailAddress}
              name="emailAddress"
              disabled={state.loading}
              error={formik.errors.emailAddress}
              onChange={formik.handleChange}
              label={translate("email_address")}
            />
              <InputField
                value={formik.values.phoneNumber}
                name="phoneNumber"
                disabled={state.loading}
                error={formik.errors.phoneNumber}
                onChange={formik.handleChange}
                label={translate("phone_number")}
              />
              <Select
                value={formik.values.country}
                name="country"
                disabled={state.loading}
                error={formik.errors.country}
                onChange={formik.handleChange}
                label={translate("country")}
                options={[
                  { label: "Canada", value: "CAD" },
                  { label: "USA", value: "USD" },
                ]}
              />

            <PasswordField
              label={translate("password")}
              value={formik.values.password}
              name="password"
              disabled={state.loading}
              error={formik.errors.password}
              onChange={formik.handleChange}
            />
            <PasswordField
              label={translate("confirm_password")}
              value={formik.values.cpassword}
              name="cpassword"
              disabled={state.loading}
              error={formik.errors.cpassword}
              onChange={formik.handleChange}
            />
          </div>

          <Button  loading={state.loading} onClick={formik.handleSubmit}>
            {translate("create_account")}
          </Button>

          <div className="border-t mt-10 pt-4 text-center">
            <p>
              {translate("have_account")} &nbsp;{" "}
              <span className="text-primary font-semibold">
                <Link to={routes.Login.path}>{translate("login")}</Link>
              </span>
            </p>
          </div>
        </div>
      </CardWrapper>
    </Layout>
  );
}
