import apiClient from "api/index";
import classNames from "classnames";
import Layout from "components/Layout";
import PrimaryButton from "components/PrimaryButton";
import SecondaryButton from "components/SecondaryButton";
import { STATUS } from "constant";
import useDispatch from "hooks/dispatch";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import React, { useEffect } from "react";
import routes from "routes/index";
import { useParams } from "react-router-dom";
import Loader from "components/Loader";
import { getPromoAmount } from "helpers/transaction";

export default function ConsultTranaction() {
  const { state, dispatch } = useDispatch({ transaction: {} });
  const params = useParams();

  const getTransactions = async () => {
    dispatch({ loading: true });
    try {
      const response = await apiClient({
        method: "get",
        url: "/transaction/" + params.id,
      });
      if (!response.data) {
        throw new Error("Invalid transaction Id");
      }
      dispatch({ transaction: response.data, loading: false });
    } catch (error) {
      dispatch({
        loading: false,
        error: error.message ?? "Something went wrong!",
      });
    }
  };
  useEffect(() => {
    getTransactions();
  }, []);

  if (state.loading) {
    return (
      <div className="py-64 flex justify-center items-center">
        <Loader size={60} />
      </div>
    );
  }

  const [name, status] = Object.entries(STATUS)?.find(
    (b) => b[1] === state.transaction.status
  ) ?? [null, null];

  const cn = classNames({
    "text-gray-500": true,
    "text-green-500": status === STATUS.COMPLETE,
    "text-red-500": status === STATUS.FAILED,
  });

  if (state.error) {
    return (
      <Layout headerBg>
        <div className="py-28 h-[50vh] flex justify-center items-center ">
          <h6 className="font-bold text-2xl">{state.error}</h6>
        </div>
      </Layout>
    );
  }

  return (
    <Layout title="Consult Transaction">
      <div>
        <section className="easy-steps">
          <div className="container space-y-8">
            <div className="flex justify-between flex-wrap gap-4 items-center w-full">
              <h6 className="text-base lg:text-3xl text-black font-semibold">
                Transaction ID:{" "}
                <span className="text-primary">{params.id}</span>
              </h6>
              <div className="flex-1 flex md:justify-end">
                {state.transaction.status !== STATUS.COMPLETE && (
                  <PrimaryButton
                    className="lg:!w-max w-full"
                    href={routes.Contact.path}
                  >
                    Contact Support
                  </PrimaryButton>
                )}
              </div>
            </div>

            <div className="space-y-4">
              <Info
                name="Tracking Number"
                value={state.transaction.trackingNumber}
              />
              <Info name="Sender" value={state.transaction.client?.fullName} />
              <Info
                name="Recipient"
                value={state.transaction.contact?.fullName}
              />
              <Info
                name="Recieving Wallet"
                value={state.transaction.contact?.wallet}
              />
              <Info name="Amount" value={getPromoAmount(state.transaction)} />
              <Info
                name="Fees"
                value={
                  <NumericFormat
                    value={Number(state.transaction.fee).toFixed(2)}
                    className="text-primary"
                    displayType="text"
                    suffix={" " + state.transaction.currency}
                  />
                }
              />
              <Info
                name="Recipient Recieve"
                value={
                  getPromoAmount(state.transaction, false)
                }
              />

              <Info name="Status" value={<span className={cn}>{name}</span>} />
              <Info
                name="Date"
                value={moment(new Date(state.transaction.created)).format(
                  "DD MMM YYYY"
                )}
              />
              {state.transaction.status === STATUS.COMPLETE && (
                <Info
                  name="Delivery Date"
                  value={
                    <span className={cn}>
                      {moment(new Date(state.transaction.deliveryDate)).format(
                        "DD MMM YYYY"
                      )}
                    </span>
                  }
                />
              )}
            </div>
            <div className="flex flex-col md:flex-row gap-4">
              {![STATUS.FAILED].includes(state.transaction.status) &&
                state.transaction.trackingNumber && (
                  <PrimaryButton
                    href={
                      routes.TrackTransaction.route +
                      state.transaction.trackingNumber
                    }
                    // className="lg:!w-max"
                  >
                    Track Transaction
                  </PrimaryButton>
                )}
              <SecondaryButton
                // className="lg:!w-max"
                href={routes.Transactions.path}
              >
                View Transactions
              </SecondaryButton>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}

const Info = ({ name, value }) => {
  return (
    <div>
      <h6 className="lg:text-xl text-base flex gap-4">
        {" "}
        <span>{name}:</span> <span>{value}</span>
      </h6>
    </div>
  );
};
