import apiClient from "api/index";
import CardWrapper from "components/CardWrapper";
import Layout from "components/Layout";
import ResultCard from "components/ResultCard";
import useDispatch from "hooks/dispatch";
import { useTranslate } from "hooks/translate";
import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import routes from "routes";

export default function VerifyAccount() {
  const { translate } = useTranslate();
  const params = useParams();
  let token = params.token;
  const { state, dispatch } = useDispatch();

  const verifyAccount = async () => {
    dispatch({ loading: true });
    try {
      await apiClient({
        method: "get",
        url: `/validate`,
        _token: token
      });
      dispatch({ loading: false });
    } catch (error) {
      dispatch({ loading: false });
    }
  };

  useEffect(() => {
    verifyAccount();
  }, []);

  return (
    <Layout hideBanner>
      <CardWrapper>
        {state.loading ? (
          <div className="flex justify-center items-center py-10 pt-20">
            <Spinner />
          </div>
        ) : (
          <ResultCard
            title={translate("account_verified")}
            description={translate("account_verified_text")}
            buttonText={translate("login_to_account")}
            buttonRoute={routes.Login.path}
          />
        )}
      </CardWrapper>
    </Layout>
  );
}
