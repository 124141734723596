import apiClient from "api/index";
import classNames from "classnames";
import Layout from "components/Layout";
import PrimaryButton from "components/PrimaryButton";
import SecondaryButton from "components/SecondaryButton";
import Table from "components/Table";
import { STATUS } from "constant";
import { getPromoAmount } from "helpers/transaction";
import useDispatch from "hooks/dispatch";
import moment from "moment";
import React, { useEffect } from "react";
import { NumericFormat } from "react-number-format";
import routes from "routes/index";

export default function Transactions() {
  const { state, dispatch } = useDispatch({ data: [] });

  const getTransactions = async () => {
    dispatch({ loading: true });
    try {
      const response = await apiClient({ method: "get", url: "/transaction" });
      dispatch({ transactions: response.data, loading: false });
    } catch (error) {
      dispatch({ loading: false });
      console.log(error);
    }
  };

  const headers = [
    {
      header: "Transaction ID",
      accessorKey: "_id",
    },
    {
      header: <div className="w-full flex justify-center">Reciever</div>,
      accessorKey: "contact",
      cell: ({ getValue }) => {
        return <div className="text-center">{getValue().fullName}</div>;
      },
    },
    {
      header: <div className="w-full flex justify-center">Amount</div>,
      accessorKey: "amount",
      cell: ({ row: { original } }) => <div className="text-center">{getPromoAmount(original)}</div>,
    },
    {
      header: <div className="w-full flex justify-center">Status</div>,
      accessorKey: "status",
      cell: ({ getValue }) => {
        const [name, status] = Object.entries(STATUS).find(
          (b) => b[1] === getValue()
        );

        const cn = classNames({
          "text-gray-500": true,
          "text-green-500": status === STATUS.COMPLETE,
          "text-red-500": status === STATUS.FAILED,
        });
        return (
          <div className="w-full flex justify-center">
            <div className={cn}>{name}</div>
          </div>
        );
      },
    },

    {
      header: <div className="w-full flex justify-center">Date</div>,
      accessorKey: "created",
      cell: ({ getValue }) => {
        return (
          <div className="text-center">
            {moment(new Date(getValue())).format("DD MMM YYYY")}
          </div>
        );
      },
    },
    {
      header: <div className="bg-red-200"></div>,
      accessorKey: "action",
      cell: ({ row: { original } }) => {
        return (
          <div className="flex items-center justify-end gap-4">
            <div>
              <SecondaryButton
                href={routes.TrackTransaction.route + original.trackingNumber}
                className="!min-w-max"
              >
                Track
              </SecondaryButton>
            </div>
            <div>
              <PrimaryButton
                href={routes.ConsultTranaction.route + original._id}
                className="!min-w-max"
              >
                View
              </PrimaryButton>
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getTransactions();
  }, []);
  return (
    <Layout title="Transactions">
      <div className="py-20">
        <section className="easy-steps">
          <div className="container space-y-8">
            <div className="flex justify-between items-center flex-wrap gap-2">
              <h6 className="text-3xl text-black font-semibold">
                Transactions
              </h6>
              <div>
                <PrimaryButton href={routes.SendMoney.path}>
                  Send Money
                </PrimaryButton>
              </div>
            </div>

            <div className="w-full">
              <Table
                loading={state.loading}
                columns={headers}
                data={state.transactions}
              />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}
