import { NumericFormat } from "react-number-format";

export const getTransactionFee = ({
  amount = 0,
  feeType,
  fee = 0,
  currency,
  rateUSD,
  rateCAD,
  receiveAmount,
}) => {
  let feeAmount = 0;
  if (feeType === "AMOUNT") {
    feeAmount = fee;
  } else {
    feeAmount = parseFloat((Number(fee) / 100) * amount).toFixed(2);
  }

  let paidAmount = receiveAmount;

  if (feeAmount) {
    paidAmount =
      currency === "USD"
        ? (amount - feeAmount) * rateUSD
        : (amount - feeAmount) * rateCAD;
  }

  return {
    feeAmount,
    paidAmount: parseFloat(paidAmount).toFixed(2),
  };
};

export const convertToUSD = ({ rateUSD, rateCAD, amount }) => {
  return parseFloat((Number(rateCAD) / Number(rateUSD)) * amount).toFixed(2);
};

export const getPromo = (amount, coupon = {}) => {
  let promoAmount = 0;
  if (coupon.type === "P") {
    promoAmount = parseFloat(
      (Number(coupon.value) / 100) * Number(amount)
    ).toFixed(2);
  } else {
    promoAmount = coupon.value;
  }

  if (coupon.maxValue & promoAmount > coupon.maxValue) {
    return Number(coupon.maxValue)
  }
  return  Number(promoAmount);
};


export const getPromoAmount = (transaction = {}, isAmount = true) => {
  let coupon = transaction.coupon;
  let amount = isAmount ? transaction.amount : transaction.paidAmount;
  let currency = isAmount ? transaction.currency : "XAF";

  if (
    coupon &&
    ((isAmount && coupon.affect === "S") ||
      (!isAmount && coupon.affect === "R"))
  ) {
    let promo = isAmount
      ? Number(amount) - getPromo(amount, coupon)
      : Number(amount) + getPromo(amount, coupon);

    return (
      <div className="flex gap-2">
        <NumericFormat
          value={isAmount ? Number(promo).toFixed(2) : Number(promo).toFixed(0)}
          thousandSeparator=" "
          displayType="text"
          className="text-primary"
          suffix={" " + currency}
        />
        (
        <NumericFormat
          value={
            isAmount ? Number(amount).toFixed(2) : Number(amount).toFixed(0)
          }
          thousandSeparator=" "
          displayType="text"
          className="line-through"
          suffix={" " + currency}
        />
        )
      </div>
    );
  }

  return (
    <NumericFormat
      value={Number(amount).toFixed(2)}
      thousandSeparator=" "
      displayType="text"
      className="text-primary mx-auto text-center"
      suffix={" " + currency}
    />
  );
};

export const getPromoText = (coupon = {}, currency) => {
  if (coupon.type === "P") {
    return coupon.value + "%";
  } else {
    return coupon.value + currency;
  }
};

export const getFloat = (str = "") => {
  if (!str) return "";
  let value = str?.match(/[-+]?(?:\d*\.?\d+|\d+\.?\d*)(?:[eE][-+]?\d+)?/)[0];
  return value;
};
