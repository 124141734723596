import base from './base.json';
import errors from './errors.json';
import warning from './warning.json';


let enTranslations = {
    ...base,
    ...errors,
    ...warning,
}

export default enTranslations