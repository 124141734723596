import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import routes from "./routes";
import { AuthContextProvider } from "./hooks/auth";

function App() {
  return (
    <>
      <Router>
        <AuthContextProvider>
          <Routes>
            {Object.values(routes).map((route) => {
              let Component = route.component;
              return <Route key={route.path} path={route.path} element={<Component />} />;
            })}
          </Routes>
        </AuthContextProvider>
      </Router>
    </>
  );
}

export default App;
