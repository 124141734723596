import Button from "components/Button";
import React from "react";
import { Link } from "react-router-dom";

export default function ResultCard({
  title,
  description,
  buttonText,
  buttonRoute,
  hideButton
}) {
  return (
    <div className="w-full max-w-lg bg-white shadow-lg p-8 text-center rounded-lg mx-auto">
      <div>
        <div className="pb-6">
          <h4 className="text-black font-semibold text-3xl">{title}</h4>
          <p>{description}</p>
        </div>
        {!hideButton && <a>
          <Link to={buttonRoute}>
            <Button>{buttonText}</Button>
          </Link>
        </a>}
      </div>
    </div>
  );
}
