import React, { useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import SEO from "components/seo";
import Banner from "components/banner";
import useDispatch from "hooks/dispatch";
import apiClient from "api/index";

export default function Layout({
  children,
  headerBg,
  offwhiteBg,
  description,
  title,
  titleCenter,
  btnText,
  btnPath,
  hideBanner,
}) {
  const { dispatch, state } = useDispatch();

  const fetchInfo = async () => {
    try {
      dispatch({ loading: true });
      const data = await apiClient({ method: "get", url: "/info" });
      dispatch({ data: data.data, loading: false });
    } catch (error) {
      dispatch({ loading: false });
    }
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  return (
    <div className={`${offwhiteBg ? "bg-[#f7faff]" : "bg-white"}`}>
      <SEO title={title} description={description} />
      <div className="flex flex-col min-h-screen">


        <Header headerBg={headerBg} />
        <main className="flex-1">
        {state.data?.news && (
          <div className="bg-primary h-12 text-white flex items-center">
            <marquee className="w-full text-xl">{state.data?.news}</marquee>
          </div>
        )}
          {!hideBanner && (
            <Banner
              title={title}
              btnPath={btnPath}
              btnText={btnText}
              titleCenter={!titleCenter}
            />
          )}
          {children}
        </main>
        <Footer />
      </div>
    </div>
  );
}
