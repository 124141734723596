import React from 'react'
import Layout from 'components/Layout'

export default function Fees() {
  return (
    <Layout title="Fees">
    <section className="package">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-10 m-auto text-center">
            <div className="sec-heading">
              <h2 className="sec-title">Best exchange rates with zero fees</h2>              
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-11 m-auto">
            <div className="package-table">
              <table>
                <tr>
                  <th>Exchange</th>
                  <th>USD</th>
                  <th>CAD</th>
                </tr>
                <tr>
                  <td>Celatrans Connect</td>
                  <td> 0% </td>
                  <td> 0% </td>
                </tr>

              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
    </Layout>
  )
}
