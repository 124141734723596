import classNames from "classnames";
import React from "react";

export default function Alert({ text, status  = "default"}) {
  const cn = classNames({
    "px-4 py-3 rounded": true,
    "bg-red-100 text-red-500": status === "danger",
    "bg-green-100 text-green-500": status === "success",
    "bg-yellow-100 text-yellow-500": status === "warning",
    "bg-blue-100 text-blue-500": status === "info",
    "bg-gray-100 text-gray-500": status === "default",

  });
  return <div className={cn}>{text}</div>;
}
