import React from "react";
import Card from "../Card";
import InputField from "../InputField";
import PrimaryButton from "../PrimaryButton";
import { useBoolean } from "../../hooks/useBoolean";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "../Button";
import apiClient from "../../api";
import Select from "components/Select";
import SecondaryButton from "components/SecondaryButton";
import { FaExclamationCircle } from "react-icons/fa";

export default function RecieverComponent({
  reciever,
  next,
  prev,
  state,
  dispatch,
}) {
  const { open, onClose, onOpen } = useBoolean();

  return (
    <>
      {open && (
        <NewReciever state={state} dispatch={dispatch} onClose={onClose} />
      )}
      {!open && (
        <Card title="Reciever">
          <div className="space-y-8">
            <div className="reciever">
              <Select
                options={state.contacts?.map((e) => ({
                  label: e.fullName,
                  value: e._id,
                }))}
                value={reciever}
                onChange={(e) => dispatch({ reciever: e.target.value })}
                label="Choose a reciever"
                placeholder="Choose a receiving contact"
              />
              <span className="accept-terms text-left !m-0 !p-0">
                Sending to a new reciever?{" "}
                <a
                  onClick={() => {
                    onOpen();
                    dispatch({ added: null });
                  }}
                  className="text-primary hover:underline"
                >
                  Add Reciever
                </a>
              </span>
            </div>

            <div className="flex items-center flex-col-reverse md:flex-row gap-4">
              <SecondaryButton onClick={prev}>Modify Amount</SecondaryButton>
              <Button className="w-full" disabled={!reciever} onClick={next}>
                Review
              </Button>
            </div>
          </div>
        </Card>
      )}
    </>
  );
}

const NewReciever = ({ onClose, state, dispatch }) => {
  const initialValues = {
    fullName: "",
    phone: "",
    wallet: "",
  };

  const addReciever = async (reciever) => {
    try {
      dispatch({ adding: true });

      let response = await apiClient({
        data: reciever,
        method: "post",
        url: "/contact",
      });

      dispatch({ adding: false, added: true, contacts: response.data });
    } catch (error) {
      dispatch({ adding: false });
      console.log(error);
    }
  };

  const validationSchema = yup.object({
    fullName: yup.string().required("Reciever full name is required"),
    wallet: yup.string().required("Reciever wallet is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: addReciever,
  });

  return (
    <div>
      {state.added ? (
        <RecieverCreated onClose={onClose} />
      ) : (
        <Card title="Add reciever">
          <h1 className="text-2xl">Add reciever</h1>
          <br />
          <div className="space-y-6">
            <InputField
              value={formik.values.fullName}
              onChange={formik.handleChange}
              error={formik.touched.fullName && formik.errors.fullName}
              label="Full Name"
              name="fullName"
              disabled={state.adding}
            />
            <InputField
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && formik.errors.email}
              label="Email"
              name="email"
              disabled={state.adding}
            />
            <InputField
              value={formik.values.wallet}
              onChange={formik.handleChange}
              error={formik.touched.wallet && formik.errors.wallet}
              label="Wallet (Mobile Money)"
              name="wallet"
              hint={
                <div className="flex gap-1">
                  <FaExclamationCircle className="mt-1" /> Enter the correct
                  Mobile Money number. You will be held accountable for any
                  errors
                </div>
              }
              disabled={state.adding}
            />
            <PrimaryButton
              loading={state.adding}
              loadingText="Saving"
              onClick={formik.submitForm}
            >
              Save
            </PrimaryButton>

            <span className="accept-terms">
              Already created reciever ? &nbsp;
              <a onClick={onClose} className="text-primary hover:underline">
                Select Receiver
              </a>
            </span>
          </div>
        </Card>
      )}
    </div>
  );
};

const RecieverCreated = ({ onClose }) => {
  return (
    <div className="w-full max-w-lg bg-white shadow-lg p-8 text-center rounded-lg mx-auto">
      <div>
        <div className="pb-6">
          <h4 className="text-black font-semibold text-lg">Contact Created</h4>
          <p>
            Your contact has been created successfully. You can now send money
            to this contact
          </p>
        </div>
        <a>
          <Button onClick={onClose}>Continue</Button>
        </a>
      </div>
    </div>
  );
};
