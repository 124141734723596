import React from "react";

import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import PrimaryButton from "components/PrimaryButton";
import SecondaryButton from "components/SecondaryButton";
import Loader from "components/Loader";

function useSkipper() {
  const shouldSkipRef = React.useRef(true);
  const shouldSkip = shouldSkipRef.current;

  const skip = React.useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  React.useEffect(() => {
    shouldSkipRef.current = true;
  });
  return [shouldSkip, skip];
}

export default function Table({
  columns = [],
  data = [],
  updateData = () => {},
  loading,
}) {
  let tableFactory = () => [...columns];

  columns = React.useMemo(tableFactory, []);

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();
  const table = useReactTable({
    data,
    columns,
    state: {
      pageSize: 100,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex,
    meta: {
      updateData: (rowIndex, columnId, value) => {
        skipAutoResetPageIndex();
        updateData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              };
            }
            return row;
          })
        );
      },
    },
    debugTable: true,
  });

  return (
    <>
      <div className="w-full">
        {loading && data.length === 0 ? (
          <div className="w-full h-[500px] flex justify-center items-center">
            <Loader size={60} />
          </div>
        ) : (
          <div className="overflow-x-auto w-full">
          <table className=" lg:w-full  table-fixed dark:divide-gray-700">
            <thead className="bg-slate-100">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th  scope="col" className="py-4 px-6 text-sm font-medium text-gray-700 uppercase tracking-wide" key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder ? null : (
                          <>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <tr className="hover:bg-gray-100 even:bg-slate-100" key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td className="py-4 px-6 whitespace-nowrap" key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          </div>
        )}

        <div className="flex justify-between items-center flex-wrap gap-4 mt-2">
          {table.getPageCount() > 1 && (
            <div className="flex gap-1 items-center">
              Page
              <span>
                {table.getState().pagination.pageIndex + 1} of{" "}
                {table.getPageCount()}
              </span>
            </div>
          )}

          {!(!table.getCanPreviousPage() && !table.getCanNextPage()) && (
            <div
              className="flex justify-end gap-2"
            >
              <SecondaryButton
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
                className="min-w-[100px]"
              >
                Prev
              </SecondaryButton>
              <PrimaryButton
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
                className="min-w-[100px]"
              >
                Next
              </PrimaryButton>
            </div>
           )}
        </div>
      </div>
    </>
  );
}
