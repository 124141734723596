import React from "react";
import CardWrapper from "components/CardWrapper";
import RecieverComponent from "./RecieverComponent";
import SendComponent from "./SendComponent";
import { useTab } from "hooks/tabs";
import Review from "./Review";
import { useQuery } from "hooks/index";
import OrderPlaced from "./OrderPlaced";

export default function SendMoneyComponent(props) {
  const query = useQuery();
  const transactionId = query.get("id");
  const clientSecret = query.get("secret");
  let index = (clientSecret && transactionId) ? 3 : 0

  const { next, prev, Component } = useTab([
    SendComponent,
    RecieverComponent,
    Review,
    OrderPlaced,
  ],  index);

  return (
    <CardWrapper>
      <Component next={next} prev={prev}  {...props} />
    </CardWrapper>
  );
}
