import React from "react";
import Card from "../Card";
import Button from "../Button";
import { convertToUSD, getTransactionFee } from "helpers/transaction";

export default function SendComponent({ dispatch, next, ...state }) {
  const handleChange = ({ target: { name, value = "" } }) => {
    let fl = {}

    value = value.replace("-", "")
    if (!value) {
      dispatch({ receiveAmount: "", amount: "" });
      return;
    }

    if (value.charAt(value.length -1) === "." && !value.slice(0, value.length -1).includes(".")) {
      fl[name] = value
      value = value+"0"
    }

    value = value.match(/[-+]?(?:\d*\.?\d+|\d+\.?\d*)(?:[eE][-+]?\d+)?/)[0]
    let rate = state.rate[state.currency] ?? 0;
    if (name === "amount") {
      let receiveAmount = value * rate;

      // let fee = getTransactionFee(value)

      dispatch({ [name]: fl[name] ? fl[name] : value, receiveAmount });
    } else if (name === "receiveAmount") {
      let amount = Number(value / rate).toFixed(2);

      // let fee = getTransactionFee(amount)
      // amount = parseFloat(Number(amount) +  Number(fee)).toFixed(2)
      dispatch({ [name]: fl[name] ? fl[name] : value, amount });
    } else if (name === "currency") {
      let amount = state.amount ?? 0;
      let rate = state.rate[value] ?? 0;
      let receiveAmount = amount * rate;
      dispatch({ [name]: value, receiveAmount });
    }
  };

  // const getTransactionFee = (amount) => {
  //   const fee = state.fee ?? 0;
  //   if (state.feeType === "AMOUNT") return state.fee
  //   return parseFloat((fee / 100) * amount).toFixed(2);
  // };

  const { feeAmount } = getTransactionFee({
    amount: state.amount,
    currency: state.currency,
    fee: state.fee,
    feeType: state.feeType,
    rateCAD: state.rate?.CAD,
    rateUSD: state.rate?.USD,
  });

  const usdFee = convertToUSD({
    amount: state.minAmount,
    rateCAD: state.rate?.CAD,
    rateUSD: state.rate?.USD,
  });

  const minAmount =
    state.currency === "USD" ? Number(usdFee) : Number(state.minAmount);

  return (
    <Card title="Send Money">
      <div>
        <div className="form-field mb-0">
          <label>send amount</label>
          <div className="border-[1px] p-x-[10px] flex items-center border-[#dadada] rounded-[5px]">
            <div className="flex w-full items-center px-[16px]">
              <span>{state.currency}</span>
              <input
                value={state.amount}
                onChange={handleChange}
                name="amount"
                className="flex-1 px-0 pl-1 m-input text-black"
                placeholder="0"
              />
            </div>
            <div className="!py-0 h-max p-[16px] border-l-[1px] border-[#dadada]">
              <div className="flex  h-full space-x-[10px] items-center">
                <img
                  className="w-[25px] h-[25px] rounded-full"
                  src={
                    state.currency === "CAD"
                      ? "images/icons/ca.png"
                      : "images/icons/us.png"
                  }
                  alt=""
                />
                <select
                  value={state.currency}
                  name="currency"
                  onChange={handleChange}
                  className="select-bg flex-1 text-black"
                >
                  <option value="CAD">CAD</option>
                  <option value="USD">USA</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        {Number(state.amount) < minAmount && (
          <p className="text-sm text-red-500">
            Minimum amount is {minAmount} {state.currency}
          </p>
        )}
      </div>
      <div className="currency-text mt-3">
        <span>
          <b>{state.rate[state.currency]}</b> Exchange Rate
        </span>
        <span>
          <b>${feeAmount}</b> Transition Fees
        </span>
      </div>
      <div className="form-field">
        <label>Receive amount</label>
        <div className="border-[1px] p-x-[10px] flex items-center border-[#dadada] rounded-[5px]">
          <div className="flex w-full items-center px-[16px]">
            <span>XAF</span>
            <input
              value={state.receiveAmount}
              onChange={handleChange}
              name="receiveAmount"
              className="flex-1 px-0 pl-1 m-input text-black"
              placeholder="0"
            />
          </div>
          <div className="!py-0  w-[182px] h-max p-[16px] border-l-[1px] border-[#dadada]">
            <div className="flex  h-full space-x-[10px] items-center">
              <img
                className="w-[25px] h-[25px] rounded-full"
                src="images/icons/cm.png"
                alt=""
              />
              <span className="text-black">CAM</span>
            </div>
          </div>
        </div>
      </div>
      <Button
        className="w-full"
        disabled={!state.amount || state.amount < 15}
        onClick={next}
      >
        Proceed
      </Button>

      <span className="accept-terms mt-2">
        By clicking to proceed, you agree to our{" "}
        <a href="#">terms and conditions</a>.
      </span>
    </Card>
  );
}
