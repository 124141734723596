import apiClient from "api/index";
import Layout from "components/Layout";
import Loader from "components/Loader";
import useDispatch from "hooks/dispatch";
import React, { useEffect } from "react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { useParams } from "react-router-dom";

export default function TrackTransaction() {
  const { state, dispatch } = useDispatch({ transaction: {} });
  const params = useParams();

  const getTransactions = async () => {
    dispatch({ loading: true });
    try {
      const response = await apiClient({
        method: "get",
        url: "/tracking/" + params.id,
      });

      
      dispatch({ transaction: response.data, loading: false });
    } catch (error) {
      dispatch({ loading: false, error: true });
      console.log(error);
    }
  };
  useEffect(() => {
    getTransactions();
  }, []);

  if (state.loading) {
    return (
      <div className="py-64 flex justify-center items-center">
        <Loader size={60} />
      </div>
    );
  }
  return (
    <Layout title="Track Transaction">
      <div>
        <section className="easy-steps">
          <div className="container space-y-10">
            {!state.error && <header className="text-center text-2xl font-medium text-black">
              Tracking Number: {params.id}
            </header>}


           {state.error ? 
            <div className="text-center text-3xl font-semibold text-black pt-32 flex justify-center gap-3 items-center"><FaTimesCircle className="text-red-500" fontSize="30px" />  Invalied tracking number</div>         
           : <div className="flex flex-col justify-center w-full lg:w-max mx-auto space-y-6">
             <TrackPoint name={"Tranfer Initiated by " + state.transaction.SENDER} isComplete={state.transaction.INITIATED} />
             <TrackPoint name="Payment complete" isComplete={state.transaction.PAYMENTCOMPLETE} />
             <TrackPoint name={`Transfering funds to recipient's wallet (${state.transaction.RECIEVER})`} isComplete={state.transaction.TRANFERSTATED}  />
             <TrackPoint name={state.transaction.TRANFERFAILED ? "Transfer Failed" : "Completed"} isFailed={state.transaction.TRANFERFAILED} isComplete={state.transaction.TRANFERCOMPLETE}  />
            </div>}
          </div>
        </section>
      </div>
    </Layout>
  );
}

const TrackPoint = ({ isComplete, name, isFailed}) => {
  return (
    <div className="flex items-center gap-4">
      <div>{isFailed ? <FaTimesCircle className="text-red-500" fontSize="30px" /> :  isComplete ? <FaCheckCircle className="text-green-500" fontSize="30px" /> : <Loader size={30} />}</div>
      <div className="flex-1 max-w-full">
      <p className="lg:text-xl text-base">{name}</p>
      </div>
    </div>
  );
};
