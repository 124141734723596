import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslate } from "hooks/translate";
import PrimaryButton from "components/PrimaryButton";
import PasswordField from "components/InputField/PasswordField";

export default function Security({changingPassword, savePassword = () => {}, savedP}) {
  const { translate } = useTranslate();
  const initialValues = {
    oldPassword: "",
    newPassword: "",
    cpassword: "",
  };

  const validationSchema = yup.object({
    oldPassword: yup.string().required(translate("field_required")),
    newPassword: yup.string().required(translate("field_required")),
    cpassword: yup
      .string()
      .oneOf([yup.ref("newPassword")], translate("password_not_match"))
      .required(translate("field_required")),
  });


  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: savePassword,
  });

  return (
    <div className="space-y-5">
      <div className="gap-4 gap-x-8 mt-10 md:grid grid-cols-1">
        <PasswordField
          label={translate("oldpassword")}
          value={formik.values.oldPassword}
          name="oldPassword"
          disabled={changingPassword}
          error={formik.errors.oldPassword}
          onChange={formik.handleChange}
        />
        <PasswordField
          label={translate("newpassword")}
          value={formik.values.newPassword}
          name="newPassword"
          disabled={changingPassword}
          error={formik.errors.newPassword}
          onChange={formik.handleChange}
        />
        <PasswordField
          label={translate("confirm_password")}
          value={formik.values.cpassword}
          name="cpassword"
          disabled={changingPassword}
          error={formik.errors.cpassword}
          onChange={formik.handleChange}
        />
      </div>
      <PrimaryButton loading={changingPassword} onClick={formik.submitForm} loadingText="Saving..." className="!w-max">{savedP ? "Saved" : "Save"}</PrimaryButton>
    </div>
  );
}
