import { createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import useDispatch from "./dispatch";
import apiClient from "../api";
import routes from "routes";
import errors from "helpers/errors";
import Cookies from "universal-cookie";

const authContext = createContext();

export function AuthContextProvider({ children }) {
  const context = useAuthContext();
  return (
    <authContext.Provider value={context}>{children}</authContext.Provider>
  );
}

export function useAuth() {
  return useContext(authContext);
}

function useAuthContext() {
  const cookie = new Cookies(null, { path: "/", maxAge: 604800 });
  const navigate = useNavigate();
  const name = cookie.get("name");
  const token = cookie.get("token");
  const { state, dispatch } = useDispatch({
    user: { name, isAuthenticated: token !== undefined },
  });

  const loginUser = async (user) => {
    dispatch({ userLoading: true, loginError: null });
    const data = user;

    try {
      const response = await apiClient({
        method: "post",
        url: "/login",
        data,
      });

      dispatch({ userLoading: false });
      const userData = {
        name: response.data.client.fullName,
        isAuthenticated: true,
      };
      cookie.set("token", response.data.token);
      cookie.set("name", userData.name);
      dispatch({ user: userData });
      navigate(routes.SendMoney.path);
    } catch (error) {
      dispatch({ userLoading: false });
      if (error.message === errors.ACCOUNT_NOT_VERIFIED) {
        navigate(routes.EmailNotVerified.path);
      } else {
        dispatch({ loginError: error.message, userLoading: false });
      }
    }
  };

  const logoutUser = () => {
    cookie.remove("name");
    cookie.remove("token");
    dispatch({ user: null });
    window.location = routes.Home.path;
  };

  return {
    ...state,
    dispatch,
    loginUser,
    logoutUser,
  };
}
