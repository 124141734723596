import React from 'react'
import { Link } from 'react-router-dom'
import routes from 'routes/index'

export default function Footer() {
  return (
    <footer className="footer">
      <div className="foo-top">
      <div className="container">
        <div className="flex justify-center gap-40">
          <div className="">
            <div className="widget foo-nav">
              <h5>Access Us</h5>
              <ul>
                <li><a href="#">Send Money</a></li>
                <li><a href="#">Tract Payments</a></li>
              </ul>
            </div>
          </div>
          <div className="">
            <div className="widget foo-nav">
              <h5>Help Center</h5>
              <ul>
                <li><Link to={routes.Contact.path}>Contact Us</Link></li>
                <li><Link to={routes.About.path}>About Us</Link></li>
              </ul>
            </div>
          </div>

        </div>
      </div>
      </div>
      <div className="foo-btm">
        <div className='container d-flex justify-content-center'>
              <div className="copyright">Copyright &copy; {new Date().getFullYear()}</div>
        </div>
      </div>
    </footer>
  )
}
