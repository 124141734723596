import React from "react";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import { useBoolean } from "../../hooks/useBoolean";
import classNames from "classnames";

export default function PasswordField({
  label = "",
  placeholder,
  name,
  value,
  onChange,
  error,
  rightElement,
}) {
  const { open: show, toogle } = useBoolean();
  const cn = classNames({
    ["form-control input-field border-[#ced4da] rounded-full"]: true,
    "border-red-500": error,
    "text-2xl": !show,
  });
  return (
    <div className="form-group ">
      <div className="flex justify-between items-center">
        <label>{label}</label>
        {rightElement && <div>{rightElement}</div>}
      </div>
      <div className="relative">
        <input
          className={cn}
          type={show ? "text" : "password"}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />

        <button
          onClick={toogle}
          className="text-gray-700 text-2xl absolute right-4 top-1/2 -translate-y-1/2"
        >
          {show ? <RiEyeOffFill /> : <RiEyeFill />}{" "}
        </button>
      </div>
      {error && <p className="text-sm text-red-500">{error}</p>}
    </div>
  );
}
