import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

export default function SecondaryButton({ href, ...rest }) {
  if (href) {
    return (
      <Link to={href}>
        <ButtonComponent {...rest} />
      </Link>
    );
  }
  return <ButtonComponent {...rest} />;
}

const ButtonComponent = ({
  children,
  onClick,
  loading,
  loadingText = "Loading...",
  className,
  ...rest
}) => {
  const cn = classNames({
    "btn btn-dark w-100 rounded-full": true,
    [className]: true,
  });

  return (
    <button disabled={loading} className={cn} onClick={onClick} {...rest}>
      {loading ? <> {loadingText} </> : <>{children}</>}
    </button>
  );
};
