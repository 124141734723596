import CardWrapper from "components/CardWrapper";
import Layout from "components/Layout";
import ResultCard from "components/ResultCard";
import { useTranslate } from "hooks/translate";
import React from "react";
import routes from "routes";

export default function AccountInActive() {
  const { translate } = useTranslate();
  return (
    <Layout hideBanner>
      <CardWrapper>
        <ResultCard
          title={translate("account_inactive")}
          description={translate("account_inactive_text")}
          buttonText={translate("contact_support")}
          buttonRoute={routes.Contact.path}
        />
      </CardWrapper>
    </Layout>
  );
}
