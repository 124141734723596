import React from "react";

export default function InputField({
  label = "",
  placeholder,
  name,
  value,
  onChange,
  error,
  disabled,
  hint,
  ...rest
}) {
  return (
    <div className="form-group">
      <label>{label}</label>
      <input
        className={`form-control border-[#ced4da] rounded-full input-field ${
          error ? "!border-red-500 !text-red-500" : ""
        }`}
        type="text"
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        {...rest}
      />
      {error && <p className="text-sm text-red-500">{error}</p>}
      {hint && <p className="text-sm text-gray-600">{hint}</p>}
    </div>
  );
}
