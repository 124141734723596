import React from "react";
import Card from "../Card";
import { NumericFormat } from "react-number-format";
import PrimaryButton from "components/PrimaryButton";
import routes from "routes/index";
import { getPromo } from "helpers/transaction";

export default function OrderPlaced({
  transactionData = {},
  amount,
  currency,
  reciever,
  contacts = [],
  couponData,
}) {
  const contact = contacts.find((contact) => contact._id === reciever) ?? {};

  return (
    <>
      <Card title="Order Placed">
        <p className="text-center">
          Your transfer of
          <span className="text-primary">
            {" "}
            {(couponData && couponData?.affect === "S") ? (
              <NumericFormat
                value={amount - getPromo(amount, couponData)}
                thousandSeparator=" "
                displayType="text"
                suffix={" " + currency}
              />
            ) : (
              <NumericFormat
                value={Number(amount)}
                thousandSeparator=" "
                displayType="text"
                suffix={" " + currency}
              />
            )}
            {" "}
          </span>
          to <span className="text-primary">{contact?.fullName}</span> has been
          initiated. The funds will be deposited into the Mobile Money Wallet
          soon.{" "}
          {getPaymentText({
            currency: currency,
            cashAppEmail: transactionData.paymentConfig?.cashAppEmail,
            interacEmail: transactionData.paymentConfig?.interacEmail,
            zelleEmail: transactionData.paymentConfig?.zelleEmail,
          })}
          <br />
          <br />
          Use this reference in your payment{" "}
          <span className="text-primary tracking-wide">
            {transactionData.transaction?.refNumber}
          </span>
          .
        </p>

        <div className="mt-4">
          <PrimaryButton
            href={
              routes.ConsultTranaction.route + transactionData.transaction?._id
            }
          >
            Consult Transaction
          </PrimaryButton>
        </div>
      </Card>
    </>
  );
}

const getPaymentText = ({
  currency,
  interacEmail,
  cashAppEmail,
  zelleEmail,
}) => {
  if (currency === "CAD") {
    return (
      <>
        Please, proceed to complete interac payment using{" "}
        <span className="text-primary">{interacEmail}</span>
      </>
    );
  }

  return (
    <>
      Please proceed to complete Zelle or CashApp payment using{" "}
      <span className="text-primary">{cashAppEmail}</span>
    </>
  );
};
