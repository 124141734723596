import CardWrapper from "components/CardWrapper";
import Layout from "components/Layout";
import ResultCard from "components/ResultCard";
import { useTranslate } from "hooks/translate";
import React from "react";
import routes from "routes";

export default function EmailNotVerified() {
  const { translate } = useTranslate();
  return (
    <Layout title="Login">
      <CardWrapper>
        <ResultCard
          title={translate("email_not_verified")}
          description={translate("email_not_verified_text")}
          buttonText={translate("login")}
          buttonRoute={routes.Login.path}
        />
      </CardWrapper>
    </Layout>
  );
}
